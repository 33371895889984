import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import Layout from "../components/layout";

import "./resume.scss";

class Resume extends React.Component {
  render() {
    console.log(this.props);
    const resume = get(this, "props.data.dataYaml.resume");
    console.log(resume);

    const pdf_link = `
http://api.pdflayer.com/api/convert
?access_key=f622570f4b454b0f89e1aaaf669eadea
&document_url=https://www.andrewmahon.info/resume/
&document_name=andrew_mahon
&page_size=Letter
&margin_top=50
&margin_bottom=50
&margin_left=50
&margin_right=50
&inline=1
&viewport=600x1000
&dpi=300
&use_print_media=1`;

    return (
      <div id="section-resume" className="content">
        <Helmet title="Resume - Andrew Mahon" />

        <h1>{resume.name}</h1>

        <div className="download-links">
          <p>
            <a href={pdf_link} target="_blank" className="pdf-link">
              PDF
            </a>
          </p>
        </div>

        <div className="personal-details details">
          <p>
            <a href={resume.personal_details.url} target="_blank">
              {resume.personal_details.url}
            </a>
          </p>
          <address>
            {resume.personal_details.street}, {resume.personal_details.city}
          </address>
          <p>
            <a href={"mailto:" + resume.personal_details.email} target="_blank">
              {resume.personal_details.email}
            </a>
          </p>
          <p>
            <a href={"tel:" + resume.personal_details.phone} target="_blank">
              {resume.personal_details.phone}
            </a>
          </p>
        </div>

        <hr />

        <h3>Experience</h3>
        {resume.professional_experience.map((experience) => {
          return (
            <div className="content-section experience" key={experience.name}>
              <h4>
                <a href={experience.url} target="_blank">
                  {experience.name}
                </a>
              </h4>
              <div className="details">
                <p>
                  {experience.dates} | <strong>{experience.title}</strong> |{" "}
                  {experience.location}
                </p>
              </div>
              <p className="description">{experience.description}</p>
              <ul>
                {experience.accomplishments.map((accomplishment, index) => {
                  return <li key={index}>{accomplishment}</li>;
                })}
              </ul>
            </div>
          );
        })}

        <hr />

        <h3>Education</h3>
        {resume.education.map((education) => {
          return (
            <div className="content-section" key={education.name}>
              <h4>
                <a href={education.url} target="_blank">
                  {education.name}
                </a>
              </h4>
              <div className="details">
                <p>
                  {education.dates}
                  &nbsp;|&nbsp;
                  <strong>{education.degree}</strong>
                  &nbsp;|&nbsp;
                  {education.location}
                </p>
              </div>
              <ul>
                {education.accomplishments.map((accomplishment, index) => {
                  return <li key={index}>{accomplishment}</li>;
                })}
              </ul>
            </div>
          );
        })}

        <hr />

        <h3>
          References{" "}
          <span className="details-upon-request">
            <a href="mailto:andrewmahon@fastmail.com">(details upon request)</a>
          </span>
        </h3>

        <hr />

        <h3>Additional Information</h3>

        <div className="content-section">
          <p>
            <strong>Awards</strong>&nbsp;|&nbsp;
            {resume.additional_information.awards.map((award, index) => {
              if (index >= resume.additional_information.awards.length - 1) {
                return <span key={index}>{award}</span>;
              } else {
                return <span key={index}>{award}, </span>;
              }
            })}
          </p>

          <p>
            <strong>Technical Skills</strong>&nbsp;|&nbsp;
            {resume.additional_information.technical_skills.map(
              (skill, index) => {
                if (
                  index >=
                  resume.additional_information.technical_skills.length - 1
                ) {
                  return <span key={index}>{skill}</span>;
                } else {
                  return <span key={index}>{skill}, </span>;
                }
              }
            )}
          </p>

          <p>
            <strong>Activities</strong>&nbsp;|&nbsp;
            {resume.additional_information.activities.map((activity, index) => {
              if (
                index >=
                resume.additional_information.activities.length - 1
              ) {
                return <span key={index}>{activity}</span>;
              } else {
                return <span key={index}>{activity}, </span>;
              }
            })}
          </p>
        </div>
      </div>
    );
  }
}

export default Resume;

export const _pageQuery = graphql`
  query ResumeQuery {
    dataYaml(document: { eq: "resume" }) {
      resume {
        additional_information {
          activities
          awards
          technical_skills
        }
        education {
          accomplishments
          dates
          degree
          gpa
          location
          name
          url
        }
        name
        personal_details {
          city
          email
          phone
          street
          url
        }
        professional_experience {
          accomplishments
          dates
          description
          location
          name
          title
          url
        }
      }
      id
    }
  }
`;
